<template>
  <table-view
    class="campus"
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <div class="header-button">
        <el-button v-permission="['basic:campus:edit']" type="primary" size="mini" @click="showDialog()"
        >添加校区
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="campusName" label="校区名称" width="300" />
      <el-table-column prop="campusNo" label="校区编码" width="100" align="center"/>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-permission="['basic:campus:edit']"
                     type="primary"
                     size="mini"
                     @click="showDialog(row.id)"
          >编辑
          </el-button>
          <el-button v-permission="['basic:campus:del']"
                     size="mini"
                     type="danger"
                     @click="del(deleteCampus, row.id,`校区-${row.campusName}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加/编辑弹窗 -->
    <campus-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" :total="total" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getCampusList, deleteCampus } from '@/api/campus'
import tableView from '@/vue/mixins/table-view'
import CampusDialog from '@/views/pages/baseInfo/campus/dialog/campusDialog.vue'

export default {
  name: 'campus',
  components: {
    CampusDialog
  },
  mixins: [tableView],
  data () {
    return {}
  },
  mounted () {
    this.renderTable(1)
  },
  methods: {
    deleteCampus, // 删除api
    async renderTable (pageNum) {
      await this.getTableData(getCampusList, pageNum)
    }
  }
}
</script>
