<template>
  <el-dialog
    class="grade-dialog"
    :title="`${preTitle}校区`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="540px"
    :before-close="cancel"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="校区名称" prop="campusName">
        <el-input v-model.trim="formData.campusName" />
      </el-form-item>
      <el-form-item label="校区编码" prop="campusNo">
        <tool-tips-group tips-content="一位数，用于公寓中心-楼况设置中的排序码。">
          <el-input-number :min="0" :max="9" v-model.trim="formData.campusNo" />
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="save('校区',addCampus)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addCampus, getCampusById } from '@/api/campus'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'campusDialog',
  mixins: [dialog],
  props: {
    total: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      getTest: this.test,
      formData: {
        id: '',
        campusName: '',
        campusNo: 0,
        remarks: ''
      },
      rules: {
        campusName: [{
          required: true,
          message: '请填写校区',
          trigger: 'blur'
        },
          this.$store.state.commonValidate.limit20WordsObj
        ]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getCampusById)
      } else {
        this.formData.campusNo = 1 + this.total
      }
    })
  },
  methods: {
    addCampus// 保存 api
  }
}
</script>
